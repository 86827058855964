* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  height: 100vh;
  width: 100%;
  overflow-x: hidden;
  transition: all 1s;
  /* 1秒かけて背景の色が変化 */
}

a {
  text-decoration: none;
}

section h1 {
  color: #fff;
}

.w-10 {
  width: 10%;
}

.w-20 {
  width: 20%;
}

.w-50 {
  width: 50%;
}

.w-60 {
  width: 60%;
}

.w-70 {
  width: 70%;
}

.w-80 {
  width: 80%;
}

.w-90 {
  width: 90%;
}

.w-full {
  width: 100%;
}

.h-20 {
  height: 20%;
}

.h-30 {
  height: 30%;
}

.h-50 {
  height: 50%;
}

.h-full {
  height: 100%;
}

.pd10 {
  padding: 0 10%;
}

.flex {
  display: flex;
}

.flex-end {
  justify-content: flex-end;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.column {
  flex-direction: column;
}

.text-center {
  text-align: center;
}

.gap5 {
  gap: 5%;
}

.gap-5 {
  gap: 5px;
}

.gap-12 {
  gap: 12px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.gap-50 {
  gap: 50px;
}

.gap2vw {
  gap: 2vw;
}

.gap4vw {
  gap: 4vw;
}

.gap5vw {
  gap: 5vw;
}

.gap10vw {
  gap: 10vw;
}

.cursor {
  cursor: pointer;
}

.mg-top40 {
  margin-top: 40px;
}

.mg-top20 {
  margin-top: 20px;
}

.pd-top50 {
  padding-top: 50px;
}

.pd-top100 {
  padding-top: 100px;
}

.uppercase {
  text-transform: uppercase;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

.inline-block {
  display: inline-block;
}

.fit-content {
  width: fit-content;
}

/* .text-shadow-blueness { 
 text-shadow: rgb(63, 58, 156) 2px 0px 0px, rgb(63, 58, 156) 1.75517px 0.958851px 0px, rgb(63, 58, 156) 1.0806px 1.68294px 0px, rgb(63, 58, 156) 0.141474px 1.99499px 0px, rgb(63, 58, 156) -0.832294px 1.81859px 0px, rgb(63, 58, 156) -1.60229px 1.19694px 0px, rgb(63, 58, 156) -1.97998px 0.28224px 0px, rgb(63, 58, 156) -1.87291px -0.701566px 0px, rgb(63, 58, 156) -1.30729px -1.5136px 0px, rgb(63, 58, 156) -0.421592px -1.95506px 0px, rgb(63, 58, 156) 0.567324px -1.91785px 0px, rgb(63, 58, 156) 1.41734px -1.41108px 0px, rgb(63, 58, 156) 1.92034px -0.558831px 0px;
}

.text-shadow-lightblue {
  text-shadow: rgb(36, 164, 222) 2px 0px 0px, rgb(36, 164, 222) 1.75517px 0.958851px 0px, rgb(36, 164, 222) 1.0806px 1.68294px 0px, rgb(36, 164, 222) 0.141474px 1.99499px 0px, rgb(36, 164, 222) -0.832294px 1.81859px 0px, rgb(36, 164, 222) -1.60229px 1.19694px 0px, rgb(36, 164, 222) -1.97998px 0.28224px 0px, rgb(36, 164, 222) -1.87291px -0.701566px 0px, rgb(36, 164, 222) -1.30729px -1.5136px 0px, rgb(36, 164, 222) -0.421592px -1.95506px 0px, rgb(36, 164, 222) 0.567324px -1.91785px 0px, rgb(36, 164, 222) 1.41734px -1.41108px 0px, rgb(36, 164, 222) 1.92034px -0.558831px 0px;
}

.text-shadow-orange {
  text-shadow: rgb(244, 113, 0) 2px 0px 0px, rgb(244, 113, 0) 1.75517px 0.958851px 0px, rgb(244, 113, 0) 1.0806px 1.68294px 0px, rgb(244, 113, 0) 0.141474px 1.99499px 0px, rgb(244, 113, 0) -0.832294px 1.81859px 0px, rgb(244, 113, 0) -1.60229px 1.19694px 0px, rgb(244, 113, 0) -1.97998px 0.28224px 0px, rgb(244, 113, 0) -1.87291px -0.701566px 0px, rgb(244, 113, 0) -1.30729px -1.5136px 0px, rgb(244, 113, 0) -0.421592px -1.95506px 0px, rgb(244, 113, 0) 0.567324px -1.91785px 0px, rgb(244, 113, 0) 1.41734px -1.41108px 0px, rgb(244, 113, 0) 1.92034px -0.558831px 0px;
}

.text-shadow-yellow {
  text-shadow: rgb(244, 159, 0) 2px 0px 0px, rgb(244, 159, 0) 1.75517px 0.958851px 0px, rgb(244, 159, 0) 1.0806px 1.68294px 0px, rgb(244, 159, 0) 0.141474px 1.99499px 0px, rgb(244, 159, 0) -0.832294px 1.81859px 0px, rgb(244, 159, 0) -1.60229px 1.19694px 0px, rgb(244, 159, 0) -1.97998px 0.28224px 0px, rgb(244, 159, 0) -1.87291px -0.701566px 0px, rgb(244, 159, 0) -1.30729px -1.5136px 0px, rgb(244, 159, 0) -0.421592px -1.95506px 0px, rgb(244, 159, 0) 0.567324px -1.91785px 0px, rgb(244, 159, 0) 1.41734px -1.41108px 0px, rgb(244, 159, 0) 1.92034px -0.558831px 0px;
}

.text-shadow-pink {
  text-shadow: rgb(245, 60, 124) 2px 0px 0px, rgb(245, 60, 124) 1.75517px 0.958851px 0px, rgb(245, 60, 124) 1.0806px 1.68294px 0px, rgb(245, 60, 124) 0.141474px 1.99499px 0px, rgb(245, 60, 124) -0.832294px 1.81859px 0px, rgb(245, 60, 124) -1.60229px 1.19694px 0px, rgb(245, 60, 124) -1.97998px 0.28224px 0px, rgb(245, 60, 124) -1.87291px -0.701566px 0px, rgb(245, 60, 124) -1.30729px -1.5136px 0px, rgb(245, 60, 124) -0.421592px -1.95506px 0px, rgb(245, 60, 124) 0.567324px -1.91785px 0px, rgb(245, 60, 124) 1.41734px -1.41108px 0px, rgb(245, 60, 124) 1.92034px -0.558831px 0px;
}

.text-shadow-blue {
  text-shadow: rgb(55, 98, 214) 2px 0px 0px, rgb(55, 98, 214) 1.75517px 0.958851px 0px, rgb(55, 98, 214) 1.0806px 1.68294px 0px, rgb(55, 98, 214) 0.141474px 1.99499px 0px, rgb(55, 98, 214) -0.832294px 1.81859px 0px, rgb(55, 98, 214) -1.60229px 1.19694px 0px, rgb(55, 98, 214) -1.97998px 0.28224px 0px, rgb(55, 98, 214) -1.87291px -0.701566px 0px, rgb(55, 98, 214) -1.30729px -1.5136px 0px, rgb(55, 98, 214) -0.421592px -1.95506px 0px, rgb(55, 98, 214) 0.567324px -1.91785px 0px, rgb(55, 98, 214) 1.41734px -1.41108px 0px, rgb(55, 98, 214) 1.92034px -0.558831px 0px;
}

.text-shadow-green {
  text-shadow: rgb(160, 210, 117) 2px 0px 0px, rgb(160, 210, 117) 1.75517px 0.958851px 0px, rgb(160, 210, 117) 1.0806px 1.68294px 0px, rgb(160, 210, 117) 0.141474px 1.99499px 0px, rgb(160, 210, 117) -0.832294px 1.81859px 0px, rgb(160, 210, 117) -1.60229px 1.19694px 0px, rgb(160, 210, 117) -1.97998px 0.28224px 0px, rgb(160, 210, 117) -1.87291px -0.701566px 0px, rgb(160, 210, 117) -1.30729px -1.5136px 0px, rgb(160, 210, 117) -0.421592px -1.95506px 0px, rgb(160, 210, 117) 0.567324px -1.91785px 0px, rgb(160, 210, 117) 1.41734px -1.41108px 0px, rgb(160, 210, 117) 1.92034px -0.558831px 0px;
} */


/* フォント設定 */
.delaney-regular {
  font-family: "delaney", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.delaney-bold {
  font-family: "delaney", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.manrope {
  font-family: 'Manrope', sans-serif;
}

/* Headerページ */
.Header {
  /* background: #D9E2FF; */
  position: fixed;
  top: 0;
  left: 0;
  height: 70px;
  width: 100%;
  padding: 20px 40px;
  z-index: 120;
}

.Header .menu div {
  font-size: 1.25vw;
}

.Header button {
  background: #fff;
  color: #000;
  padding: 4px 16px;
  border: none;
  border-radius: 15px;
}

.Header .logo img {
  width: 30%;
}

.social-media a:not(:last-child) {
  padding-right: 20px;
}

/* ------------------------------------- */
/* -------------Topページ -------------- */
/* ------------------------------------- */

#TopPage {
  position: relative;
  z-index: 10;
  height: 110vh;
  /* background: #FEEBF2; */
}

.TopBg {
  background: #FEEBF2;
}

.AboutBg {
  background: #FEEBF2;
}

.MintBg {
  background: #D9E2FF;
}

.CharaBg {
  background: #FFE2D6;
}

.TeamBg {
  background: #EBFEEC;
}

.QABg {
  background: #BCEDFA;
}

.FooterBg {
  background: #FF7EAB;
}


.topHeader {
  padding: 0 40px;
  position: relative;
  z-index: 105;
  background: transparent;
}

.underline {
  padding-bottom: 3px;
  position: relative;
}

.underline::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: #000;
  height: 2px;
  width: 100%;
  transform-origin: right top;
  transform: scale(0, 1);
  transition: transform .5s;
}

.underline:hover::before {
  transform-origin: left top;
  transform: scale(1, 1);
}

#TopPage button {
  background: #fff;
  color: #000;
  padding: 4px 16px;
  border: none;
  border-radius: 10px;
}

#TopPage .menu div {
  /* display: inline-block; */
  /* margin-right: 20px; */
}

#TopPage .menu {
  font-size: 1.25vw;
}

#TopPage .logo {
  margin-top: 10px;
}

#TopPage .logo img {
  width: 70%;
}

#TopPage .imageShadow {
  background: #F5D6E1;
  width: 90vw;
  height: 80vh;
  transform: rotate(-2deg);
}

#TopPage .imageShadow-2 {
  background: #FADFE9;
  transform: rotate(2deg) scale(1);
}

.TopVisual {
  transform: rotate(0deg) scale(1);
  width: 100%;
  position: relative;
  z-index: 10;
}

.SelfiesTop {
  width: 45%;
  left: 0;
  bottom: 0;
  z-index: 100;
  transform: rotate(-2deg);
}

.scrollText {
  text-align: center;
  color: #f53c7c;
  font-size: 1.25vw;
}

.letter13 {
  top: 10%;
  right: 0%;
  z-index: 20;
  width: 15%;
}

.letter14 {
  top: 5%;
  left: 30%;
  z-index: 20;
  width: 15%;
}

.letter15 {
  top: 30%;
  left: 0;
  z-index: 20;
  width: 15%;
}

.letter16 {
  bottom: 20%;
  right: -2%;
  z-index: 20;
  width: 15%;
}

.letter17 {
  bottom: 0;
  right: 15%;
  z-index: 20;
  width: 15%;
}

.i1 {
  bottom: 35%;
  right: 2%;
  z-index: 20;
  width: 4%;
}
.i2 {
  bottom: 40%;
  right: 0%;
  z-index: 20;
  width: 3%;
}
.i3 {
  bottom: 13%;
  right: 1%;
  z-index: 20;
  width: 3%;
}
.i4 {
  top: 7%;
  left: 46%;
  z-index: 20;
  width: 4%;
}
.i5 {
  top: 10%;
  left: 27%;
  z-index: 20;
  width: 3%;
}
.i6 {
  top: 21%;
  right: 1%;
  z-index: 20;
  width: 5%;
}
.i7 {
  top: 28%;
  left: 4%;
  z-index: 20;
  width: 1.5%;
}
.i8 {
  top: 46%;
  left: 2%;
  z-index: 20;
  width: 1.5%;
}

.letter_anime {
  animation: fuwafuwa 4s infinite;
}

@keyframes fuwafuwa {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.letter_anime2 {
  animation: fuwafuwa2 4s infinite 0.5s;
}

@keyframes fuwafuwa2 {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}

.letter_anime3 {
  animation: fuwafuwa2 4s infinite 1s;
}

@keyframes fuwafuwa3 {
  0% {
    transform: translateY(0px);
  }

  50% {
    transform: translateY(-10px);
  }

  100% {
    transform: translateY(0px);
  }
}


/* Aboutページ */

/* ------------------------------------- */
/* ------------- Aboutページ ----------- */
/* ------------------------------------- */

#AboutPage {
  /* background: #FEEBF2; */
  color: #f53c7c;
  height: 120vh;
}

.bgPic .aboutPic {
  width: 40%;
}

#AboutPage .title {
  position: relative;
  z-index: 100;
}

.title svg {
  width: 100vw;
}

#AboutPage text {
  color: #fff;
  stroke: #f53c7c;
  stroke-width: 2px;
  fill: #fff;
  stroke-dasharray: 2500;
  /* ストロークの線の長さ */
  stroke-dashoffset: 1300;
  /* 線の始まりの位置を指定する */
  animation: textAboutAnime 2s ease-out forwards;
  animation-delay: 2s;
  opacity: 0;
  z-index: 100;
  position: relative;
}

.SPAboutPage text {
  color: #fff;
  stroke: #f53c7c;
  stroke-width: 1px;
  fill: #fff;
  stroke-dasharray: 1300;
  /* ストロークの線の長さ */
  stroke-dashoffset: 1300;
  /* 線の始まりの位置を指定する */
  animation: textAboutAnime 2s ease-out forwards;
  animation-delay: 2s;
  opacity: 0;
  z-index: 100;
  position: relative;
}

@keyframes textAboutAnime {
  0% {
    stroke-dashoffset: 1300;
    fill: transparent;
  }

  50% {
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 0;
    fill: #fff;
    opacity: 1;
  }
}

#AboutPage .front {
  padding-top: 0px;
  position: inherit;
  z-index: 100;
}

#AboutPage .front {
  padding-left: 100px;
}

.firstArea {
  font-size: 4vw;
  font-weight: 500;
}

#MintPage .firstArea :first-child {
  margin-bottom: 0px;
}

.firstArea :nth-child(2) {
  margin-bottom: 16px;
}

.firstArea .aboutText span {
  padding-left: 4px;
}

.firstArea .mintText span {
  padding-left: 4px;
}

.secondArea {
  font-size: 2.25vw;
  font-weight: 600;
  margin-bottom: 16px;
}

#AboutPage .secondArea span span {
  padding: 0 4px;
}

.thirdArea {
  width: 50%;
  font-size: 1.5vw;
}

#AboutPage .thirdArea .aboutText {
  padding: 1vh .5vw;
}

.aboutText {
  background: #fff;
  border: #f53c7c 2px solid;
}

#AboutPage .title {
  padding: 12vh 0 0 9.5vw;
}

.title {
  font-size: 11vw;
}

.subtitle {
  font-size: 10vw !important;
}

.subtitle svg {
  width: 60vw;
}

.about04 {
  top: 0;
  left: -22%;
  z-index: 1;
}

.about01 {
  top: 20%;
  right: 15%;
  z-index: 5;
}

.about06 {
  z-index: 3;
  top: 0;
  right: -9%;
}

.about05 {
  bottom: -40%;
  left: -8%;
}

.about02 {
  bottom: -30%;
  right: 45%;
  z-index: 3;
}

.about03 {
  bottom: -40%;
  right: -14%;
}

.zIndex1 {
  z-index: 1;
}

.zIndex2 {
  z-index: 2;
}

.zIndex3 {
  z-index: 3;
}

.zIndex4 {
  z-index: 4;
}

.zIndex5 {
  z-index: 5;
}

.zIndex6 {
  z-index: 6;
}

@media (min-width:1920px) {
  #AboutPage .title {
    padding: 100px 0 0 100px;
  }

  #AboutPage .front {
    padding-top: 30px;
  }

  .title {
    font-size: 15vw;
  }

  #QAPage .title {
    font-size: 11vw;
  }

  .subtitle {
    font-size: 5vw !important;
  }

  .bgPic .aboutPic {
    width: 45%;
  }

  .bgPic .about02 {
    width: 41%;
  }

  .firstArea {
    font-size: 4em;
    font-weight: 500;
  }

  .secondArea {
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .thirdArea {
    width: 29%;
    font-size: 1.25rem;
  }

  .about04 {
    top: 0;
    left: -21%;
    z-index: 1;
  }

  .about01 {
    top: 20%;
    right: 10%;
    z-index: 5;
  }

  .about06 {
    right: -9%;
    z-index: 3;
  }

  .about05 {
    bottom: -70%;
    left: -10%;
  }

  .about02 {
    bottom: -50%;
    right: 40%;
    z-index: 3;
  }

  .about03 {
    bottom: -70%;
    right: -21%;
    z-index: 6;
  }
}

.letter01 {
  top: 10%;
  right: 20%;
  z-index: 20;
  width: 20%;
}

.letter02 {
  top: 25%;
  right: 5%;
  z-index: 20;
  width: 15%;
}

.letter03 {
  bottom: 10%;
  right: 13%;
  z-index: 20;
  width: 15%;
}

.i9 {
  top: 8%;
  left: 11%;
  z-index: 20;
  width: 3%;
}
.i10 {
  top: 19%;
  right: 37%;
  z-index: 20;
  width: 1%;
}
.i11 {
  top: 20%;
  right: 15%;
  z-index: 20;
  width: 1.5%;
}
.i12 {
  top: 50%;
  right: 13%;
  z-index: 20;
  width: 1%;
}
.i13 {
  bottom: 30%;
  right: 17%;
  z-index: 20;
  width: 2%;
}
.i14 {
  bottom: 7%;
  right: 23%;
  z-index: 20;
  width: 1.5%;
}
.i15 {
  bottom: 25%;
  right: 15%;
  z-index: 20;
  width: 1%;
}

.invisible {
  visibility: hidden;
}

.appear {
  animation: header .3s ease-in-out;
}

@keyframes header {
  0% {
    transform: translateY(-60px);
  }
  100% {
    transform: translateY(0px);
  }
}

/* ------------------------------------ */
/* ------------Mintページ------------- */
/* ------------------------------------ */

#MintPage {
  color: #3f3a9c;
  height: 132vh;
  /* background: #D9E2FF; */
}

.mintContainer {
  position: relative;
  z-index: 100;
}

#MintPage .title {
  padding-top: 100px;
  padding-left: 100px;
}

#MintPage text {
  color: #fff;
  stroke: #3f3a9c;
  stroke-width: 2px;
  fill: #fff;
  stroke-dasharray: 3300;
  /* ストロークの線の長さ */
  stroke-dashoffset: 2000;
  /* 線の始まりの位置を指定する */
  animation: textMintAnime 2s ease-out forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

.SPMintPage text {
  color: #fff;
  stroke: #3f3a9c;
  stroke-width: 1px;
  fill: #fff;
  stroke-dasharray: 2000;
  /* ストロークの線の長さ */
  stroke-dashoffset: 2000;
  /* 線の始まりの位置を指定する */
  animation: textMintAnime 2s ease-out forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

@keyframes textMintAnime {
  0% {
    stroke-dashoffset: 2000;
    fill: transparent;
  }

  50% {
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 0;
    fill: #fff;
    opacity: 1;
  }
}

#MintPage .front {
  flex-grow: 1;
  padding-left: 0;
}

.mintBtn {
  background: #3f3a9c;
  color: #736EC3;
  border-radius: 20px;
  border: none;
  padding: 15px 50px;
}

.mintContent {
  padding: 0 5%;
  margin-bottom: 120px;
}

.MintRightArea {
  flex-grow: 1;
  width: 30%;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-weight: 500;
}

.MintRightArea p {
  margin-bottom: 60px;
  font-size: 1.25vw;
  line-height: 1.75vw;
}

.mintInfo {
  gap: 30px;
  font-size: 1.25rem;
}

.mintInfo .information::after {
  display: block;
  content: "";
  height: 1px;
  width: 100%;
  background: #3f3a9c;
}

.mintInfo .information div {
  margin-bottom: .4rem;
}

.mintInfo .information span:nth-child(2) {
  font-size: 1.5rem;
  font-weight: 400;
}

.mintInfo .information:last-child {
  margin-bottom: 2rem;
}

.mintText {
  background: #fff;
  border: #3f3a9c 2px solid;
}

.center-text {
  text-align: center;
}

.sc01 {
  transform: rotate(-3deg);
  position: inherit;
  z-index: 100;
}

.sc02 {
  transform: rotate(2deg);
  position: inherit;
  z-index: 90;
}

.letter04 {
  top: 5%;
  right: 5%;
  width: 15%;
  z-index: 20;
}

.i16 {
  top: 5%;
  right: 17%;
  width: 1.5%;
  z-index: 20;
}
.i17 {
  top: 12%;
  right: 7%;
  width: 1.5%;
  z-index: 20;
}
.i18 {
  top: 11%;
  right: 20%;
  width: 1%;
  z-index: 20;
}

.item12 {
  top: 35%;
  left: -2%;
  width: 13%;
  z-index: -1;
}

.i19 {
  top: 52%;
  left: 20%;
  width: 1.5%;
  z-index: 100;
}
.i20 {
  top: 52%;
  left: 5%;
  width: 1.5%;
  z-index: 100;
}
.i21 {
  top: 46%;
  left: 16%;
  width: 1.5%;
  z-index: 100;
}
.i22 {
  bottom: 32%;
  right: 10%;
  width: 1.5%;
  z-index: 100;
}
.letter05 {
  top: 45%;
  left: 5%;
  width: 15%;
  z-index: 100;
}

.item13 {
  top: 45%;
  left: 35%;
  width: 15%;
  z-index: 100;
}

.item14 {
  top: 42%;
  right: -2%;
  width: 7%;
  z-index: 100;
}


.slickContainer {
  padding-left: 30px;
  overflow: hidden;
  width: 100%;
}

#MintPage .slick-arrow {
  display: none !important;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* ------------------------------------- */

#MintPage .slick-slider {
  width: 100%;
}

#MintPage .slick-list {
  position: relative;
  display: block;
  width: 100%;
  height: 44vh;
  overflow: hidden;
  margin: 0;
}

#MintPage .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

#MintPage .slick-slide {
  margin-right: 0px;
  width: 4.5% !important;
  /* ここでサイズ変更 */
  display: inline-block;
}

#MintPage .slick-slide img {
  object-fit: cover;
  width: 95%;
  /* ここで画像サイズ変更可  */
  height: auto;
}

/* ------------------------------------ */
/* ------------Characterページ------------- */
/* ------------------------------------ */

#CharacterPage {
  color: #f47100;
}

.CharacterPage {
  /* background: #FFE2D6; */
}


.CharacterPage .container {
  padding-top: 40vh;
}

#CharacterPage .title {
  padding-top: 0vh;
  padding-left: 50px;
}

#CharacterPage text {
  color: #fff;
  stroke: #f47100;
  stroke-width: 2px;
  fill: #fff;
  stroke-dasharray: 1500;
  /* ストロークの線の長さ */
  stroke-dashoffset: 750;
  /* 線の始まりの位置を指定する */
  animation: textCharaAnime 2s ease-out forwards;
  animation-delay: .5s;
  opacity: 0;
}

.SPCharacterPage text {
  color: #fff;
  stroke: #f47100;
  stroke-width: 1px;
  fill: #fff;
  stroke-dasharray: 750;
  /* ストロークの線の長さ */
  stroke-dashoffset: 750;
  /* 線の始まりの位置を指定する */
  animation: textCharaAnime 2s ease-out forwards;
  animation-delay: .5s;
  opacity: 0;
}

@keyframes textCharaAnime {
  0% {
    stroke-dashoffset: 750;
    fill: transparent;
  }

  50% {
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 0;
    fill: #fff;
    opacity: 1;
  }
}

.characterText {
  background: #fff;
  border: #f47100 2px solid;
}

.characterText {
  padding: 4px 16px;
}

.catchCopy {
  display: none;
  font-size: 2vw;
  transform: translateY(9px);
  position: relative;
  z-index: 10;
}

.nonBottomLine {
  background: #fff;
  border: #f47100 2px solid;
  border-bottom: none;
  padding: 0 24px 0 16px;
}

.item01 {
  width: 20%;
  top: 20%;
  right: 10%;
  z-index: 100;
}

.letter06 {
  top: -15%;
  right: 7%;
  width: 18%;
  z-index: 100;
}

.letter07 {
  top: 20%;
  left: 0%;
  width: 20%;
  z-index: 100;
}

.letter18 {
  bottom: 0%;
  left: .5%;
  z-index: 100;
}
.letter18 img{
  width: 60%;
}
.i26 {
  bottom: 0%;
  left: .5%;
  width: 1.5%;
  z-index: 100;
}
.i27 {
  bottom: 30%;
  left: 8%;
  width: 1.5%;
  z-index: 100;
}
.i28 {
  bottom: 5%;
  left: 15%;
  width: 1.5%;
  z-index: 100;
}

.letter19 {
  top: -5%;
  right: -10%;
  z-index: 100;
}

.letter19 img {
  width: 50%;
}

.letter20 {
  bottom: 0%;
  right: 19%;
  width: 1.5%;
  z-index: 100;
}

.i23 {
  top: 15%;
  right: 15%;
  width: 1.5%;
  z-index: 100;
}

.i24 {
  top: -13%;
  right: 20%;
  width: 1.5%;
  z-index: 100;
}

.i25 {
  top: -3%;
  right: 0%;
  width: 3%;
  z-index: 100;
}


.profile {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.mainProfile {
  top: 0;
}

.w-90 {
  width: 90%;
}

.slickContent {
  position: absolute;
  top: -20%;
  left: 15vw;
}

.character {
  width: 35%;
}

#CharacterPage .front {
  top: 17vw;
  left: 30vw;
  padding-left: 0;
  width: 100%;
}

.one {
  transition: all .2s;
}

.one:hover {
  transform: rotate(-10deg);
}

#CharacterPage .thirdArea {
  width: 40vw;
}

.verticalName {
  writing-mode: vertical-rl;
  top: 35%;
  right: 0%;
  z-index: 100;
  font-size: 2rem;
}

.characterMiniPics {
  position: relative;
  padding: 120px 4vw 120px 4vw;
}

.characterMiniPics .slick-slide {
  display: inline-block;
}

.chara {
  width: 100%;
  cursor: pointer;
}
.charaSP {
  width: 100%;
  cursor: pointer;
}

.left5 {
  left: 5%;
}

.slickSelectName {
  position: absolute;
  left: -5%;
  bottom: -10%;
  z-index: 10;
}


/* ------------------------------------- */
/* ------------------------------------- */
/* ------------------------------------- */

/* CharacterPage Slick */


.characterMiniText {
  background: #fff;
  border: #f47100 1px solid;
  padding: 2px 4px;
  font-size: 1.25vw;
  letter-spacing: 1px;
}

.salaTextBox {
  white-space: nowrap;
  font-size: 1vw;
  height: 2vw;
}

.azukiTextBox {
  white-space: nowrap;
}

.CharacterPage .slick-slider {
  width: 100%;
}

.CharacterPage .slick-slide {
  margin-right: 0px;
  display: inline-block;
  min-height: 500px;
}

.CharacterPage .slick-list {
  position: relative;
  display: block;
  width: 100%;
  margin: 0;
}

.CharacterPage .slick-arrow {
  display: none !important;
}

.CharacterPage .slickCard {
  position: relative;
  max-width: 90%;
  height: auto;
}

.CharacterPage .character {
  width: 16%;
}

.CharacterPage .slick-dots {
  display: none !important;
  display: flex;
  padding: 0 30px;
  gap: 30px;
  margin-top: 120px;
  padding-bottom: 120px;
}

.mainProfile .textArea {
  width: 20%;
}

/* ------------------------------------- */
/* ----------PlayGroundページ-------------- */
/* ------------------------------------- */

#PlayGroundPage {
  /* background: #FFE2D6; */
  color: #000;
}

#PlayGroundPage h1 {
  padding-top: 50px;
  padding-left: 50px;
}

#PlayGroundPage .container {
  padding-right: 100px;
}

.playGround01 {
  width: 100%;
}

.pgLeft {
  flex-basis: 60%;
  margin-bottom: 30px;
}

.pgRight {
  flex-basis: 40%;
  margin-bottom: 50px;
}


.ball {
  display: inline-block;
  width: 20px;
  height: 20px;
  background: #F47100;
  border-radius: 50%;
}

.generateArea input {
  border-radius: 10px;
  border: #666 1px solid;
}

.generateArea button {
  background: #000;
  color: #fff;
  padding: 8px 32px;
  border-radius: 20px;
  text-transform: uppercase;
  cursor: pointer;
  margin: 10px 0 50px 0;
}

.item02 {
  top: 5%;
  right: 0;
  z-index: 5;
}

.item03 {
  top: 25%;
  right: 0;
  z-index: 5;
}

.item11 {
  left: -7%;
  bottom: -10%;
  z-index: 5;
}

.item {
  width: 200px;
}

/* ------------------------------------ */
/* ------------Teamページ------------- */
/* ------------------------------------ */

#TeamPage {
  /* background: #EBFEEC; */
  color: #a0d275;
  padding: 30px 50px 100px 50px;
}

.black {
  color: #000;
}

.white {
  color: #fff;
}

#TeamPage text {
  color: #fff;
  stroke: #a0d275;
  stroke-width: 2px;
  fill: #fff;
  stroke-dasharray: 1700;
  /* ストロークの線の長さ */
  stroke-dashoffset: 1050;
  /* 線の始まりの位置を指定する */
  animation: textTeamAnime 2s ease-out forwards;
  animation-delay: .5s;
  opacity: 0;
}

.SPTeam text {
  color: #fff;
  stroke: #a0d275;
  stroke-width: 1px;
  fill: #fff;
  stroke-dasharray: 1050;
  /* ストロークの線の長さ */
  stroke-dashoffset: 1050;
  /* 線の始まりの位置を指定する */
  animation: textTeamAnime 2s ease-out forwards;
  animation-delay: .5s;
  opacity: 0;
}

@keyframes textTeamAnime {
  0% {
    stroke-dashoffset: 1050;
    fill: transparent;
  }

  50% {
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 0;
    fill: #fff;
    opacity: 1;
  }
}

#TeamPage .subtitle text {
  color: #fff;
  stroke: #a0d275;
  stroke-width: 1px;
  fill: #fff;
  stroke-dasharray: 750;
  /* ストロークの線の長さ */
  stroke-dashoffset: 400;
  /* 線の始まりの位置を指定する */
  animation: textsubTeamAnime 2s ease-out forwards;
  animation-delay: .5s;
  opacity: 0;
}

@keyframes textsubTeamAnime {
  0% {
    stroke-dashoffset: 400;
    fill: transparent;
  }

  50% {
    fill: transparent;
  }

  100% {
    stroke-dashoffset: 0;
    fill: #fff;
    opacity: 1;
  }
}

.Produce .imageShadow {
  background: rgba(212, 245, 214, 1);
  width: 100%;
  height: 35vh;
  transform: translate(8px, 16px);
}



.imageShadow-2 {
  background: #FADFE9;
  transform: rotate(2deg) scale(1);
}

.memberBox {
  color: #fff;
  background: #5AB20F;
  padding: 0 8px;
}

.memberBox h3 {
  font-family: 'Manrope', sans-serif;
  font-weight: 200;
  font-size: 1rem;
}

.memberText {
  color: #000;
  font-family: 'Manrope', sans-serif;
}

.teamText {
  background: #fff;
  border: rgb(160, 210, 117) 2px solid;
  padding: 2px 4px;
}

#TeamPage .secondArea {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 16px;
}

.item04 {
  top: 5%;
  right: 12%;
  width: 15%;
  z-index: 100;
}

.i59 {
  top: 10%;
  left: 50%;
  width: 5%;
  z-index: 100;
}

.item05 {
  top: 30%;
  left: -2%;
  width: 13%;
  z-index: 100;
}

.item06 {
  top: 58%;
  right: -2%;
  width: 11%;
  z-index: 100;
}

.i29 {
  top: 63%;
  left: 0%;
  width: 11%;
  z-index: 100;
}
.i30 {
  top: 69%;
  left: 30%;
  width: 2%;
  z-index: 100;
}

.item07 {
  bottom: -3%;
  left: -2%;
  width: 15%;
  z-index: 100;
}

.letter08 {
  top: 15%;
  right: 0%;
  width: 20%;
  z-index: 100;
}

.letter09 {
  top: 47%;
  right: 40%;
  width: 15%;
  z-index: 100;
}

.i31 {
  top: 49%;
  right: 10%;
  width: 2%;
  z-index: 100;
}
.i32 {
  top: 48%;
  right: 3%;
  width: 2%;
  z-index: 100;
}
.i33 {
  top: 47%;
  right: 17%;
  width: 2%;
  z-index: 100;
}
.i34 {
  top: 78%;
  left: 2%;
  width: 3%;
  z-index: 100;
}
.i35 {
  bottom: 11%;
  left: 50%;
  width: 2%;
  z-index: 100;
}
.i36 {
  bottom: 2.5%;
  left: 46%;
  width: 1.5%;
  z-index: 100;
}
.i37 {
  bottom: 1%;
  right: 19%;
  width: 1.5%;
  z-index: 100;
}
.i38 {
  bottom: 0%;
  right: 25%;
  width: 1.5%;
  z-index: 100;
}

.teamContainer {
  margin-top: 50px;
}

.subtitle div {
  color: #fff;
  font-size: 3rem;
}

.paint01 {
  top: 35%;
  right: -3%;
}

.paint01 img {
  width: 40%;
}

.paint02 {
  top: 10%;
  right: 0;
}

.paint02 img {
  width: 40%;
}

.Produce .box {
  background: #fff;
  padding: 30px 50px 30px 30px;
}

.Produce .leftSide {
  flex-basis: 30%;
}

.Produce .rightSide {
  flex-basis: 70%;
}

.Produce .rightSide p {
  font-size: .7rem;
}

.Produce h2 {
  color: #5AB20F;
  font-size: 2rem;
}

.wagmi_records {
  width: 100%;
}

.ABCAnimation_TheBattle {
  width: 30%;
}

.Creative h1 {
  color: #fff;
  font-size: 3rem;
}

.Creative h2 {
  color: #5AB20F;
  font-size: 2rem;
  font-weight: 500;
  padding: 8px 0;
}

.Creative .box {
  background: #fff;
  padding: 20px 50px 20px 20px;
  transform: skew(1deg, -1deg);
}

.Creative .imageShadow {
  background: rgba(212, 245, 214, 1);
  width: 100%;
  height: 45vh;
  top: 20px;
  left: 30px;
  transform: skew(0.5deg, -0.5deg);
}

.creativeContainter {
  gap: 50px;
}

.Creative .leftSide {
  flex-basis: 30%;
}

.Creative .rightSide {
  flex-basis: 70%;
}

.Creative .memberName::before {
  position: absolute;
  top: 0;
  display: block;
  content: "";
  background: #5AB20F;
  height: 1px;
  width: 100%;
}

.Creative .memberName::after {
  position: absolute;
  bottom: 0;
  display: block;
  content: "";
  background: #5AB20F;
  height: 1px;
  width: 100%;
}

.fillGreen {
  fill: #5AB20F;
}

.memberText p {
  font-size: .75rem;
}

.Creative .memberProfile {
  flex-wrap: wrap;
}

.Creative .miniBox {
  background: #fff;
  padding: 10px;
  box-shadow: 6px 6px 0px 0px rgba(212, 245, 214, 1);
  width: calc(33% - 100px);
  flex-grow: 1;
}

.skew1 {
  transform: skew(-1deg, 1deg);
}

.skew2 {
  transform: skew(1deg, -1deg);
}

.miniContainer .leftSide {
  flex-basis: 45%;
}

.miniContainer .rightSide {
  flex-basis: 55%;
}

.miniContainer .rightSide h3 {
  color: #5AB20F;
  font-weight: 500;
}

.miniContainer .rightSide h4 {
  font-weight: 500;
  font-size: .6rem;
}

.miniContainer .rightSide p {
  font-weight: 500;
  font-size: .4rem;
}

.miniContainer .rightSide .memberBox:first-child {
  width: 70%;
}

.miniContainer .rightSide .memberBox:nth-child(2) {
  width: 80%;
}

.member {
  width: 100%;
  height: auto;
}

.miniContainer .rightSide .icons {
  padding-right: 16px;
}

/* ------------------------------------ */
/* ------- What's Selfiesページ--------- */
/* ------------------------------------ */

#QAPage {
  /* background: #BCEDFA; */
  padding: 120px 50px 200px 50px;
}

#QAPage .secondArea {
  font-size: 1.2rem;
  font-weight: 500;
  margin-bottom: 8px;
}

.QAText {
  background: #fff;
  border: #24A4DE 1px solid;
  color: #24A4DE;
  padding: 2px 4px;
  display: block;
  font-size: .85rem;
}

#QAPage text {
  color: #fff;
  stroke: #24A4DE;
  stroke-width: 2px;
  fill: #fff;
  stroke-dasharray: 2200;
  /* ストロークの線の長さ */
  stroke-dashoffset: 1850;
  /* 線の始まりの位置を指定する */
  animation: textQAAnime 2s ease-out forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

.SPQAPage text {
  color: #fff;
  stroke: #24A4DE;
  stroke-width: 1px;
  fill: #fff;
  stroke-dasharray: 1850;
  /* ストロークの線の長さ */
  stroke-dashoffset: 1850;
  /* 線の始まりの位置を指定する */
  animation: textQAAnime 2s ease-out forwards;
  animation-delay: 0.5s;
  opacity: 0;
}

#QAPage svg {
  height: 22vh;
}

@keyframes textQAAnime {
  0% {
    stroke-dashoffset: 1850;
    fill: transparent;
  }

  50% {
    fill: #fff;
  }

  100% {
    stroke-dashoffset: 0;
    fill: #fff;
    opacity: 1;
  }
}

.QAText1 {
  width: 50%;
}

.QAText2 {
  width: 60%;
}

#QAPage .textArea {
  margin-bottom: 100px;
}

#QAPage .item {
  width: 200px;
}

.item08 {
  top: 10%;
  right: -2%;
  width: 15%;
  z-index: -1;
}

.item09 {
  top: 48%;
  left: -1%;
  width: 12%;
  z-index: -1;
}
.i41 {
  top: 71%;
  left: 4%;
  width: 2%;
  z-index: -1;
}
.i39 {
  top: 71%;
  right: 40%;
  width: 2%;
  z-index: -1;
}
.i40 {
  top: 72%;
  right: 35%;
  width: 2%;
  z-index: -1;
}
.i42 {
  top: 69%;
  right: 28%;
  width: 2%;
  z-index: -1;
}

.item10 {
  bottom: 5%;
  right: 0%;
  width: 12%;
  z-index: -1;
}

.letter10 {
  top: 37%;
  right: 10%;
  width: 25%;
  z-index: -1;
}

.letter11 {
  top: 42%;
  left: 20%;
  width: 12%;
  z-index: -1;
}

.letter12 {
  bottom: 2%;
  left: 15%;
  width: 12%;
  z-index: -1;
}

.QAArea {
  flex-wrap: wrap;
  gap: 50px;
}

.QAContainer {
  width: calc(50% - 60px);
}

.QATitle:first-of-type {
  z-index: 10;
}

.QuestionBox {
  background: #fff;
  /* width: calc(50% - 60px); */
  padding: 50px 30px;
  color: #24A4DE;
  flex-grow: 1;
  overflow: hidden;
}

.QuestionBox .QATitle::after {
  display: block;
  content: "";
  background: #24A4DE;
  height: 5px;
  width: 100%;
}

.QuestionBox h2 {
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 2.5rem;
}

.QuestionBox p {
  font-size: 1.25rem;
}

.number {
  width: 100px;
}

.numberPosition {
  top: -11%;
  right: 0;
}

.letter_anime_skew3 {
  animation: fuwafuwaSkew3 4s infinite;
}

@keyframes fuwafuwaSkew3 {
  0% {
    transform: skew(-7deg, 7deg) translateY(0px);
  }

  50% {
    transform: skew(-7deg, 7deg) translateY(-10px);
  }

  100% {
    transform: skew(-7deg, 7deg) translateY(0px);
  }
}

.letter_anime_skew4 {
  animation: fuwafuwaSkew4 4s infinite 2s;
}

@keyframes fuwafuwaSkew4 {
  0% {
    transform: skew(7deg, -7deg) translateY(0px);
  }

  50% {
    transform: skew(7deg, -7deg) translateY(-10px);
  }

  100% {
    transform: skew(7deg, -7deg) translateY(0px);
  }
}

.letter_anime_skew5 {
  animation: fuwafuwaSkew5 4s infinite 2s;
}

@keyframes fuwafuwaSkew5 {
  0% {
    transform: skew(3deg, -3deg) translateY(0px);
  }

  50% {
    transform: skew(3deg, -3deg) translateY(-10px);
  }

  100% {
    transform: skew(3deg, -3deg) translateY(0px);
  }
}

.letter_anime_skew6 {
  animation: fuwafuwaSkew6 4s infinite 2s;
}

@keyframes fuwafuwaSkew6 {
  0% {
    transform: skew(-3deg, 3deg) translateY(0px);
  }

  50% {
    transform: skew(-3deg, 3deg) translateY(-10px);
  }

  100% {
    transform: skew(-3deg, 3deg) translateY(0px);
  }
}

.QAArea .imageShadow {
  background: #ABE3F2;
  width: 100%;
  height: 100%;
  top: 20px;
  left: 20px;
}

.QAArea .imageShadow2 {
  background: #ABE3F2;
  width: 100%;
  height: 100%;
  bottom: 20px;
  right: 20px;
}

.QAArea .imageShadow3 {
  background: #ABE3F2;
  width: 100%;
  height: 100%;
  top: 40px;
  left: 0px;
}

.QAArea .imageShadow4 {
  background: #ABE3F2;
  width: 100%;
  height: 100%;
  top: 30px;
  right: 20px;
}

/* ------------------------------------ */
/* ------------Footerページ------------- */
/* ------------------------------------ */


.Footer {
  background: #FF7EAB;
  color: #fff;
  padding: 30px 0;
}

.footerTitle {
  padding: 0 100px 0 50px;
  gap: 20%;
}

.footerTitle p {
  font-size: 1rem;
  /* width: 70%; */
}

.Selfies_footer {
  width: 35%;
}

.footerList:not(:last-child) {
  margin-right: 10px;
}

/* .footerImages div {
  width: 30%;
} 

.footerImages img {
  width: 100%;
} */

.footerInfomation {
  padding: 0 50px;
}

.underline2 {
  padding-bottom: 3px;
  position: relative;
}

.underline2::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  height: 2px;
  width: 100%;
  transform-origin: right top;
  transform: scale(0, 1);
  transition: transform .5s;
}

.underline2:hover::before {
  transform-origin: left top;
  transform: scale(1, 1);
}

.i43 {
  top: 40%;
  left: -10%;
  width: 50%;
  z-index: 3;
}

.i44 {
  top: 0%;
  left: 20%;
  z-index: 3;
  width: 4%;
}
.i45 {
  top: 0%;
  right: 20%;
  width: 6%;
  z-index: 3;
}
.i46 {
  bottom: 0%;
  right: 20%;
  width: 5%;
  z-index: 3;
}

.i47 {
  bottom: 10%;
  left: 0%;
  width: 4%;
  z-index: 3;
}

.i48 {
  bottom: 30%;
  right: 11%;
  width: 7%;
  z-index: 3;
}

.i49 {
  top: 10%;
  left: -26%;
  width: 70%;
  z-index: 3;
}
.i50 {
  top: 0%;
  left: 0%;
  width: 70%;
  z-index: 3;
}

.name1 {
  color: #F40271;
  transform: rotate(-10deg);
  font-size: 3.5vw;
  white-space: nowrap;
  bottom: 10%;
  right: 6%;
  z-index: 3;
}

.i51 {
  top: 17%;
  left: -5%;
  z-index: 3;
  width: 6%;
}

.i52 {
  top: 10%;
  right: 8%;
  z-index: 3;
  width: 4%;
}

.i53 {
  bottom: 0%;
  right: 30%;
  z-index: 3;
  width: 6%;
}
.i54 {
  top: 24%;
  left: 0%;
  z-index: 3;
  width: 6%;
}
.i55 {
  bottom: 20%;
  right: 10%;
  z-index: 3;
  width: 8%;
}

.i56 {
  bottom: 15%;
  right: 10%;
  z-index: 3;
  width: 4%;
}

.i57 {
  top: 27%;
  left: -15%;
  width: 70%;
  z-index: 1;
}

.Footer .slickPic {
  position: relative;
  z-index: 2;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* ------------------------------------- */

.Footer .slickContainer {
  padding: 0;
  position: inherit;
  overflow: hidden;
  width: 100%;
}

/* .Footer .slick-slider {
  width: 100%;
} */

.Footer .slick-arrow {
  display: none !important;
}

.Footer .slick-list {
  position: relative;
  display: block;
  width: 100%;
  height: 70vh;
  overflow: hidden;
  margin: 0;
}

.Footer .slick-track {
  /* position: relative;
  top: 0;
  left: 0;
  display: flex;
  width: 30%!important; */
}

.Footer .slick-slide {
  margin-right: 0px;
  width: 2.5% !important;
  display: inline-block;
}

.Footer .slick-slide img {
  object-fit: cover;
  width: 90%;
  height: auto;
}

/*
.Footer .slick-slide div:first-child {
  display: inline-block;
} */


/* ------------------------------------- */
/* ------------------------------------- */
/* ------------------------------------- */


@media (min-width:1920px) {
  #CharacterPage .firstArea {
    font-size: 4vw;
  }

  #CharacterPage .thirdArea {
    padding: 10px 10px 10px 16px;
  }

  #CharacterPage .secondArea {
    font-size: 2.5rem;
    font-weight: 600;
    margin-bottom: 16px;
  }

  .title svg {
    width: 70rem;
    height: 20rem;
  }

  .subtitle svg {
    width: 20vw;
  }

  #CharacterPage .title svg {
    width: 80vw;
    height: 30vh;
  }

  .title svg {
    width: 80vw;
    height: 30vh;
  }

  #TeamPage .title svg {
    height: 40vh;
  }

  #QAPage .title svg {
    width: 100vw;
    height: 50vh;
  }

  .Produce h2 {
    font-size: 4rem;
  }

  .Produce .leftSide {
    justify-content: center;
  }

  .wagmi_records {
    width: 80%;
  }

  .Produce .rightSide {
    gap: 30px;
  }

  .ABCAnimation_TheBattle {
    width: 25%;
  }

  .Produce .rightSide p {
    font-size: 1rem;
  }

  .Creative h2 {
    font-size: 4rem;
    padding: 8px 0;
  }

  .teamText {
    font-size: 2rem;
  }

  .memberText p {
    font-size: 1.25rem;
  }

  .memberName span {
    margin-right: 20px;
  }

  .memberName svg {
    width: 30px;
    height: 45px;
  }

  .memberBox h3 {
    font-family: 'Manrope', sans-serif;
    font-weight: 200;
    font-size: 2rem;
  }

  .memberBox {
    color: #fff;
    background: #5AB20F;
    padding: 0 8px;
    width: 56%;
  }

  .miniContainer .rightSide p {
    font-weight: 500;
    font-size: .8rem;
  }

  .miniContainer .rightSide .memberBox:first-child {
    width: 40%;
  }

  .miniContainer .rightSide .memberBox:nth-child(2) {
    width: 50%;
  }

  #QAPage .textArea {
    margin-bottom: 180px;
  }

  .QAText {
    font-size: 2rem;
  }

  .QAText1 {
    width: 45%;
  }

  .QAText2 {
    width: 56%;
  }

  .footerList:not(:last-child) {
    margin-right: 30px;
  }
}





@media (max-width:1255px) {
  .Creative .miniBox {
    padding: 10px;
    width: calc(40% - 100px);
  }
}

@media (max-width:1012px) {
  .Creative .miniBox {
    padding: 10px;
    width: calc(50% - 100px);
  }
}

@media (min-width:1000px) {
  section h1 {
    color: #fff;
    font-size: 100px;
  }
}

/* ------------------------------------------------------ */
/* ------------------------------------------------------ */
/* ------------------------------------------------------ */

.iPadTopVisual {
  background: #FEEBF2;
  overflow: hidden;
  height: 100vh;
}

.iPadTopVisual header {
  height: 60px;
  background: transparent;
  padding: 10px 6vw 0 6vw;
  margin-bottom: 20px;
  z-index: 100;
}

.iPadTopVisual .navMenuline {
  height: 90%;
  width: 3vw;
  position: relative;
  transition: all 0.3s;
}

.iPadTopVisual .navMenuline::before {
  content: "";
  position: absolute;
  top: 1.5vh;
  display: block;
  background: #000;
  height: .5px;
  width: 100%;
}

.iPadTopVisual .navMenuline::after {
  content: "";
  position: absolute;
  bottom: 2.5vh;
  display: block;
  background: #000;
  height: .5px;
  width: 100%;
}

.iPadTopVisual .navMenuline:active:before {
  transform: rotate(20deg);
}

.iPadTopVisual .navMenuline:active::after {
  transform: rotate(-20deg);
}

.iPadTopVisual .imageShadow {
  background: #F5D6E1;
  height: 90vh;
  width: 85%;
  transform: rotate(-3deg);
}

.iPadTopVisual .imageShadow-2 {
  background: #FADFE9;
  height: 90vh;
  width: 85%;
  transform: rotate(2deg) scale(1);
}

.iPadTopVisual .imageShadow-3 img {
  width: 80%;
}

.iPadTopVisual .underContent {
  margin-top: 60px;
}

.iPadTopVisual .shadow {
  height: 100vh;
  width: 100%;
}

.iPadTopVisual .SelfiesTop {
  width: 100%;
  left: 25%;
  bottom: 15%;
  z-index: 100;
  transform: rotate(-2deg);
}

.iPadTopVisual .scrollText {
  font-size: 2vw;
}

.iPadTopVisual .spLogo img {
  width: 40%;
}

.SPTopVisual .spLogo img {
  width: 40%;
}

.iPadMenu .spLogo img {
  width: 30%;
}

.iPadMenu {
  background: #FEEBF2;
  padding: 10px 6vw 3vh 6vw;
  color: #000;
}

.iPadMenu header {
  height: 60px;
  background: transparent;
}

.iPadMenu .middle {
  flex-grow: 1;
  font-size: 9vw;
}

.iPadMenu .icons button {
  background: #fff;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  font-size: 5vw;
}

/* ------------------------------------------------------ */
/* ------------------------------------------------------ */
/* ------------------------------------------------------ */

.SPTopVisual {
  background: #FEEBF2;
  overflow-x: hidden;
}

.height100svh {
  height: 100svh;
}

.SPTopVisual header {
  height: 60px;
  background: transparent;
  padding: 10px 6vw 0 6vw;
  margin-bottom: 20px;
  z-index: 100;
}

.SPMenu .spLogo img {
  width: 28%;
}

.SPTopVisual .navMenuline {
  height: 90%;
  width: 10vw;
  position: relative;
  transition: all 0.3s;
}

.SPTopVisual .navMenuline::before {
  content: "";
  position: absolute;
  top: 1.5vh;
  display: block;
  background: #000;
  height: .5px;
  width: 100%;
}

.SPTopVisual .navMenuline::after {
  content: "";
  position: absolute;
  bottom: 2.5vh;
  display: block;
  background: #000;
  height: .5px;
  width: 100%;
}

.SPTopVisual .navMenuline:active:before {
  transform: rotate(20deg);
}

.SPTopVisual .navMenuline:active::after {
  transform: rotate(-20deg);
}

.SPTopVisual .imageShadow {
  background: #F5D6E1;
  height: 60vh;
  width: 100%;
  transform: rotate(-3deg);
}

.SPTopVisual .imageShadow-2 {
  background: #FADFE9;
  height: 60vh;
  width: 100%;
  transform: rotate(2deg) scale(1);
}

.SPTopVisual .imageShadow-3 img {
  width: 105%;
}

.SPTopVisual .underContent {
  height: calc(100svh - 250px);
  margin-top: 60px;
}

.shadow {
  height: 60vh;
  width: 100%;
}

.SPTopVisual .SelfiesTop {
  width: 70%;
  left: 17%;
  bottom: -13%;
  z-index: 100;
  transform: rotate(-2deg);
}

.SPTopVisual .scrollText {
  font-size: 4vw;
}

.gap35vw {
  gap: 35vw;
}

.gap30vw {
  gap: 30vw;
}

.gap8vw {
  gap: 8vw;
}

.gap7vw {
  gap: 7vw;
}

.SPMenu {
  background: #FEEBF2;
  padding: 10px 6vw 3vh 6vw;
  color: #000;
}

.SPMenu header {
  height: 60px;
  background: transparent;
}

.SPMenu .middle {
  flex-grow: 1;
  font-size: 9vw;
}

.SPMenu .icons button {
  background: #fff;
  border-radius: 5px;
  border: none;
  padding: 5px 10px;
  font-size: 5vw;
}

/* ------------------------------------------------------ */
/* ------------------------------------------------------ */
/* ------------------------------------------------------ */

.SPAboutPage {
  background: #FEEBF2;
  color: #f53c7c;
  height: 100vh;
}

.SPAboutPage .bgPic .aboutPic {
  width: 65%;
}

.SPAboutPage .about04 {
  top: 0;
  left: -15%;
  z-index: 1;
}

.SPAboutPage .about01 {
  top: 20%;
  right: -5%;
  z-index: 5;
}

.SPAboutPage .about06 {
  z-index: 3;
  top: 0;
  right: -14%;
}

.SPAboutPage .about05 {
  bottom: 0%;
  left: -8%;
}

.SPAboutPage .about02 {
  bottom: 15%;
  right: -10%;
  z-index: 3;
}

.SPAboutPage .about03 {
  bottom: -15%;
  right: -14%;
}

.SPAboutPage .title {
  font-size: 15vw;
}

.SPAboutPage .content {
  z-index: 100;
  padding-left: 6vw;
}

.SPAboutPage .firstArea {
  font-size: 8vw;
  font-weight: 500;
}

.mr-b6 {
  margin-bottom: 6px;
}

.SPAboutPage .secondArea {
  font-size: 6vw;
  font-weight: 600;
  margin-bottom: 16px;
}

.SPAboutPage .thirdArea {
  width: 70%;
}

.SPAboutPage .secondArea .aboutText {
  padding: 0 5px;
}

.SPAboutPage .thirdArea .aboutText {
  padding: 5px;
  font-size: 3vw;
  line-height: 1.75;
}

/* ------------------------------------------------------ */
/* ------------------------------------------------------ */
/* ------------------------------------------------------ */

.SPMintPage {
  height: 100vh;
  width: 100%;
  color: #3f3a9c;
  background: #D9E2FF;
}

.SPMintContainer {}

.SPMintPage .title {
  font-size: 15vw;
}

.SPMintPage .content {
  padding-left: 6vw;
  z-index: 100;
}

.SPMintContainer .mintContent {
  padding: 0 6vw;
  margin-top: 3vh;
  margin-bottom: 40px;
}

.SPMintContainer .mintContent .details {
  position: relative;
}

.SPMintContainer .mintContent .details::before {
  content: "";
  position: absolute;
  display: block;
  height: 1px;
  width: 100%;
  background: #3f3a9c;
  bottom: -5px;
}

.SPMintContainer .mintContent .mintBtn {
  border-radius: 30px;
  font-size: 6vw;
}

.SPMintContainer .mintContent .details .left {
  font-size: 3vw;
}

.SPMintContainer .mintContent .details .right {
  font-size: 4.5vw;
}

.SPMintContainer .mintContent .text {
  font-size: 3.5vw;
}

.SPMintPage .firstArea {
  font-size: 8vw;
  font-weight: 500;
}

.mr-b6 {
  margin-bottom: 6px;
}

.SPMintPage .secondArea {
  font-size: 6vw;
  font-weight: 600;
  margin-bottom: 16px;
}

.SPMintPage .slick-arrow {
  display: none !important;
}

/* ------------------------------------- */

.SPMintPage .slick-slider {
  width: 100%;
}

.SPMintPage .slick-list {
  position: relative;
  display: block;
  width: 100%;
  overflow: hidden;
  margin: 0;
}

.SPMintPage .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
}

.SPMintPage .slick-slide {
  margin-right: 0px;
  width: 4.5% !important;
  /* ここでサイズ変更 */
  display: inline-block;
}

.SPMintPage .slick-slide img {
  object-fit: cover;
  width: 95%;
  /* ここで画像サイズ変更可  */
  height: auto;
}

.SPMintPage .slickContainer {
  padding-left: 0;
  height: 100px;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* ------------------------------------- */

.SPCharacterPage {
  color: #f47100;
  background: #FFE2D6;
  width: 100%;
}

.SPCharacterPage .title {
  padding: 0 6vw;
  font-size: 13vw;
}

.SPCharacterPage .item01 {
  width: 20%;
  top: 20%;
  right: 10%;
  z-index: 100;
}

.SPCharacterPage .slickArea {
  height: 60vh;
}

.SPCharacterPage .imageContent {
  position: relative;
  z-index: 1;
}

.SPCharacterPage .textContent {
  position: relative;
  padding: 0 6vw;
  z-index: 2;
}

.SPCharacterPage .imageContent .mainPic {
  top: -5vh;
}

.SPCharacterPage .surround {
  border: #F47100 1px solid;
  background: #fff;
  display: inline-block;
  white-space: nowrap;
}

.SPCharacterPage .one {
  padding: 2px 10px;
  font-size: 7vw;
}

.SPCharacterPage .two {
  padding: 0px 10px;
  font-size: 5vw;
}

.SPsara {
  font-size: 5.5vw !important;
}

.SPCharacterPage .surround2 {
  border-top: #F47100 1px solid;
  border-right: #F47100 1px solid;
  border-left: #F47100 1px solid;
  background: #fff;
  display: inline-block;
  padding: 5px 10px 2px 10px;
  font-size: 6vw;
  position: relative;
  z-index: 2;
  transform: translateY(2px);
}

.SPCharacterPage .surround3 {
  border: #F47100 1px solid;
  background: #fff;
  display: inline-block;
  padding: 5px 10px 10px 10px;
  font-size: 3vw;
  position: relative;
  z-index: 1;
}

.w-300 {
  width: 300%;
}

.SPCharacterPage .characterMiniPics {
  padding: 0;
}

.SPCharacterPage .appendDot {
  gap: 30px;
}

.SPCharacterPage .chara {
  width: 30%;
}

.SPCharacterPage .slick-arrow {
  display: none !important;
}

.SPCharacterPage .slick-slide {
  display: inline-block;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* ------------------------------------- */


.SPTeam {
  background: #EBFEEC;
  color: #5AB20F;
}

.SP .title {
  font-size: 15vw;
}

.SPTeamContainer {
  padding: 0 6vw 5vh 6vw;
}

.SP .secondArea {
  font-size: 3.6vw;
  letter-spacing: .5px;
  font-weight: 400;
}

.SPTeam .secondArea {
  margin-bottom: 8px;
}

.SPTeam .box {
  background: #fff;
}

.SPTeam .container {
  padding: 4vh 8vw;
}

.SPTeam .wagmi_records {
  width: 90%;
}

.SPTeam .ABCAnimation_TheBattle {
  width: 60%;
}

.SPTeam .rightSide h2 {
  font-size: 9vw;
}

.SPTeam .rightSide p {
  line-height: 2;
  font-size: 3.5vw;
}

.SPTeam .creativeContainter {
  background: #fff;
}

.SPTeam .rightSide .memberName {
  border-top: #5AB20F 1px solid;
  border-bottom: #5AB20F 1px solid;
  padding: 5px 0;
}

.SPTeam .memberContent {
  background: #fff;
  padding: 10px;
}

.SPTeam .memberProfile {
  margin-top: 30px;
}


.bgGreen {
  background: #5AB20F;
  padding: 1px 3px;
}

.fonts3vw {
  font-size: 3vw;
}

.fonts4vw {
  font-size: 4vw;
}

.fonts5vw {
  font-size: 5vw;
}

/* ------------------------------------- */
/* ------------------------------------- */
/* ------------------------------------- */

.SPQAPage {
  background: #BCEDFA;
  height: 190vh;
}

.SPQAPage .titleContanier {
  padding: 0 6vw;
  height: 30vh;
}

.SPQAPage .title {
  height: 25vh;
}

.transY {
  transform: translateY(-60px);
}

.SPQAPage .secondArea {
  margin-bottom: 4px;
}

.SPQAContainer {
  margin-top: 30vw;
  gap: 15vw;
}

.SPQAContainer .number {
  width: 20vw;
}

.SPQAContainer .numberPosition {
  top: -16%;
}

.SPQAContainer .QuestionBox {
  background: #fff;
  padding: 4vh 8vw;
  color: #24A4DE;
  flex-grow: 1;
  /* box-shadow: 6px 6px 0px 0px #ABE3F2; */
  overflow: hidden;
}

.SPQAContainer .QuestionBox p {
  font-size: 3vw;
  line-height: 1.75;
}

.SPQAContainer .QuestionBox h2 {
  font-weight: 500;
  margin-bottom: 8px;
  font-size: 5vw;
}


/* ------------------------------------- */
/* ------------------------------------- */
/* ------------------------------------- */

.SPFooter {
  background: #FC7EAB;
  color: #fff;
  overflow: hidden;
}

.footerLogo {
  padding: 4vh 0;
}

.SPFooter .navMenu {
  padding: 0 5vw 2vh 5vw;
}

.SPFooter .navMenu a {
  color: #fff;
}

.SPFooter svg {
  fill: #fff;
}

.SPFooter .slickArea {
  height: 30vh !important;
  flex-grow: 1;
}

.SPFooter .slick-arrow {
  display: none !important;
}

.SPFooter .slickArea img {
  max-height: 60vh !important;
  width: 100%;
  height: 100%;
}

.SPFooter .slickArea {
  overflow: hidden;
  width: 100%;
}

.SPFooter .slick-slider {
  width: 100%;
  height: 100% !important;
  /* display: flex;
  align-items: center;
  justify-content: center; */
}

.SPFooter .slick-list,
.SPFooter .slick-track,
.SPFooter .slick-slide,
.SPFooter .slick-cloned,
.SPFooter .slick-cloned div {
  /* height: 100%; */
}

.SPFooter .slick-cloned div {
  /* width: 60%; */
}

.SPFooter .slick-cloned .box {
  height: 100%;
}

.SPFooter .slick-cloned .box img {
  /* width: 50%!important; */
  /* height: 50%!important; */
}

.SPFooter .slick-slide {
  /* width: auto!important; */
  margin-right: 20px;
}

/* .SPFooter .slick-list {
  position: relative;
  display: block;
  width: 100%;
  height: 400px;
  overflow: hidden;
  margin: 0;
} */

/* .SPFooter .slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
} */

.SPFooter .slick-slide {
  /* margin-right: 0px; */
  /* width: 4.5% !important; */
  /* ここでサイズ変更 */
  display: inline-block;
}

/*
.SPFooter .slick-slide img {
  object-fit: cover;
  width: 95%;
  /* ここで画像サイズ変更可
  height: auto;
}*/